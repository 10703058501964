// $theme-colors: (
// 	// 'primary': #205275,
// 	// 'primary-light': #3c7095,
// 	// 'secondary': #ddd,
// 	// 'danger': #dc3545,
// 	// 'success': green,
// 	// 'info': teal,
// 	// 'dark': #666
// );
// $theme-colors: map-merge($theme-colors, $extra-theme-colors);

$primary: #205275;
$primary-light: #3c7095;
$secondary: #ddd;
// $danger: #dc3545;
$success: green;
$info: teal;
$dark: #666;


// $primary: orange; // set the $primary variable
// // merge with existing $theme-colors map
// $theme-colors: map-merge($theme-colors, ('primary': $primary,
// 			'primary-light': $primary-light,
// 			'secondary': $secondary,
// 			'danger': $danger,
// 			'success': $success,
// 			'info': $info,
// 			'dark': $dark));

@media (min-width: 576px) {
	.vh-sm-100 {
		min-height: 100vh;
	}
}

.vh-90 {
	height: 90vh !important;
}

.spinner-border {
	color: $primary;
}

.login-img {
	background: url(https://images.unsplash.com/uploads/1413387158190559d80f7/6108b580) no-repeat fixed;
	background-size: cover;
	background-position: center;
}

.table .btn-link {
	padding: 0;
}

.menuBtn {
	z-index: 1;
	box-shadow: 0px 0px 2px rgba(255, 255, 255, 1),
		0px 2px 4px 0px rgba(0, 0, 0, 0.25);

	.darkIcon {
		margin-top: 2px;
	}
}

.sidebar {
	height: 100vh;

	.trainingTitle {
		top: 57px;
	}

	.ProgressBar {
		height: 5px;
		border-radius: 0;
	}
}

.certification {
	// background: url('https://prioriza.eng.br/certification.png') no-repeat center;
	// height: 893px;
	// width: 1263px;
	position: relative;
	width: 100%;

	img {
		width: 100%;
		height: auto;
	}

	.info {
		position: absolute;
		width: 82%;
		top: 23%;
		left: 50%;
		// transform: translate(-50%, -50%);
		transform: translate(-50%);
		text-align: center;
	}

	span {
		display: block;
	}

	.congratulates {
		font-size: 1.8vw;
		margin: 1.5% 0;
	}

	.name {
		font-size: 4.5vw;
		white-space: nowrap;
	}

	.completed {
		font-size: 1.8vw;
		margin: 3% 0;
	}

	.title {
		font-size: 3.5vw;
	}

	.date {
		font-size: 1.8vw;
		margin-top: 4%;
	}
}

.react-player {
	height: auto !important;
	width: auto !important;
	aspect-ratio: 16/9;
	// pointer-events: none;
}

@media (max-width: 970px) and (max-height: 490px) and (orientation: landscape) {
	.playerContainer {
		display: flex;
		justify-content: center;
		background-color: #000;
	}

	.react-player {
		height: 100vh !important;
	}
}

.playerPause {
	transition: opacity 0.2s ease-in-out;

	&:hover {
		opacity: 0.25 !important;
	}
}

.react-player>div {
	overflow: hidden;
	width: 100%;
	/* Keep it the right aspect-ratio */
	aspect-ratio: 16/9;
	/* No clicking/hover effects */
	// pointer-events: none;
}

// remove youtube stuff
.react-player.youtube>div>iframe {
	/* Extend it beyond the viewport... */
	width: 300%;
	height: 100%;
	/* ...and bring it back again */
	margin-left: -100%;
	// pointer-events: none;
}

.yt-cover {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 1000;
	// pointer-events: auto;
}